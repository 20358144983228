// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDcBE-4DlRRaRDtVDh_jLWAigrs_xDSSic",
  authDomain: "crypto-advisor-enterprise.firebaseapp.com",
  projectId: "crypto-advisor-enterprise",
  storageBucket: "crypto-advisor-enterprise.appspot.com",
  messagingSenderId: "149204508176",
  appId: "1:149204508176:web:13c02c83dd781e949af495"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);