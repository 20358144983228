import { Formik } from "formik";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as yup from 'yup';
import { useAuth } from "../contexts/AuthContext";
import useDataService from "../hooks/useDataService";
import { ROUTES } from "../routes";

function Signup({setTempKey, ...props}) {
    const navigate = useNavigate();

    const { createNewUser } = useAuth();
    const { apiCreateClient } = useDataService();

    const signupSchema = yup.object().shape({
        firstName: yup.string().required('First name is required'),
        lastName: yup.string().required('Last name is required'),
        company: yup.string().required('Company name is required'),
        email: yup.string().required('Email is required').email('Invalid email'),
        password: yup.string().required('Password is required').min(6, 'Password must be at least 6 characters long'),
        confirmPassword: yup.string().required('Password confirmation is required').oneOf([yup.ref('password')], 'Passwords must match'),
        comments: yup.string().nullable().max(255)
    });

    const createUser = async (values, { setSubmitting }) => {
        const user = await createNewUser(values.email, values.password);
        const token = await user.getIdToken();
        const response = await apiCreateClient({
            email: values.email,
            company: values.company,
            firstName: values.firstName,
            lastName: values.lastName,
            comments: values.comments
        }, token);
        setTempKey(response.key);
        navigate(ROUTES.Dashboard);
    }


    return (
        <Container className="mt-5 mb-5">
            <Row className="justify-content-around mb-2">
                <Col md={6} className="text-center">
                    <h2>Get Early Access</h2>
                    <p>CryptoAdvisor is currently in development. Create your account by filling out the form below to get your API keys for early access.</p>
                </Col>
            </Row>
            <Row className="justify-content-around">
                <Col md={6}>
                    <Formik
                        initialValues={{
                            firstName: "",
                            lastName: "",
                            company: "",
                            email: "",
                            password: "",
                            confirmPassword: "",
                            comments: ""
                        }}
                        validationSchema={signupSchema}
                        validateOnChange={true}
                        onSubmit={createUser}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleSubmit,
                            isSubmitting
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Row>
                                    <Col>
                                        <Form.Group controlId="firstName">
                                            <Form.Label>First name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={values.firstName}
                                                onChange={handleChange}
                                                placeholder="First name"
                                                isInvalid={!!errors.firstName && touched.firstName}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group controlId="lastName">
                                            <Form.Label>Last name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={values.lastName}
                                                onChange={handleChange}
                                                placeholder="Last name"
                                                isInvalid={!!errors.lastName && touched.lastName}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Form.Group controlId="company">
                                    <Form.Label className="mt-2">Company name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={values.company}
                                        onChange={handleChange}
                                        placeholder="Company name"
                                        isInvalid={!!errors.company && touched.company}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.company}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="email">
                                    <Form.Label className="mt-2">Email</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={values.email}
                                        onChange={handleChange}
                                        placeholder="Enter your email"
                                        isInvalid={!!errors.email && touched.email}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="password">
                                    <Form.Label className="mt-2">Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        value={values.password}
                                        onChange={handleChange}
                                        placeholder="Password"
                                        isInvalid={!!errors.password && touched.password}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="confirmPassword">
                                    <Form.Label className="mt-2">Confirm password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        value={values.confirmPassword}
                                        onChange={handleChange}
                                        placeholder="Confirm password"
                                        isInvalid={!!errors.confirmPassword && touched.confirmPassword}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.confirmPassword}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="comments">
                                    <Form.Label className="mt-2">What interests you about CryptoAdvisor? (optional)</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        value={values.comments}
                                        onChange={handleChange}
                                        placeholder="Type something..."
                                        isInvalid={!!errors.comments}
                                    />
                                </Form.Group>

                                <Button 
                                    className="w-100 mt-3"
                                    type="Submit"
                                    disabled={isSubmitting}
                                >
                                        {isSubmitting ? 
                                            'Loading' :
                                            'Get Early Access'
                                        }
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
        </Container>
    )
}

export default Signup;