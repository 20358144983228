import axios from "axios";
import { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { auth } from "../services/firebase-service";

const useDataService = () => {
    const { idToken: token } = useAuth();

    const client = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        validateStatus: (status) => status >= 200 && status < 300
    });

    const apiCreateClient = async (userData, token) => {
        const response = await client.post('/clients', userData, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    }

    const apiGetClient = async () => {
        const response = await client.get('/clients');
        return response.data;
    }

    const apiGetClientKeys = async () => {
        const response = await client.get('/clients/keys');
        return response.data;
    }

    const apiRotateClientKeys = async (id) => {
        const response = await client.put(`/clients/keys/${id}`);
        return response.data;
    }

    return {
        apiCreateClient,
        apiGetClient,
        apiGetClientKeys,
        apiRotateClientKeys
    }
}

export default useDataService;