import { Formik } from "formik";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import * as yup from 'yup';
import { useAuth } from "../contexts/AuthContext";

function Login({next, setUser, ...props}) {

    const { login } = useAuth();

    const loginSchema = yup.object().shape({
        email: yup.string().required().email(),
        password: yup.string().required()
    });

    const loginUser = async (values, { setSubmitting }) => {
        const user = await login(values.email, values.password);
        console.log("signed up")
        // fetch(`${process.env.REACT_APP_API_URL}/users`, {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify(values)
        // })
        // .then(res => res.json())
        // .then(data => {
        //     setSubmitting(false);
        //     setUser(data);
        //     next();
        // })
        // .catch(err => console.log(err))
    }


    return (
        <Container className="mt-5 mb-5">
            <Row className="justify-content-around">
                <Col md={4}>
                    <h2>Log in to your account</h2>
                    <Formik
                        initialValues={{
                            email: "",
                            password: ""
                        }}
                        validationSchema={loginSchema}
                        validateOnChange={false}
                        onSubmit={loginUser}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleSubmit,
                            isSubmitting
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group controlId="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={values.email}
                                        onChange={handleChange}
                                        placeholder="Enter your email"
                                        isInvalid={!!errors.email}
                                    />
                                </Form.Group>
                                <Form.Group controlId="password">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        value={values.password}
                                        onChange={handleChange}
                                        placeholder="Password"
                                        isInvalid={!!errors.password}
                                    />
                                </Form.Group>
                                <Button 
                                    className="w-100 mt-3"
                                    type="Submit"
                                    disabled={isSubmitting}
                                >
                                        {isSubmitting ? 
                                            'Loading' :
                                            'Log in'
                                        }
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
        </Container>
    )
}

export default Login;