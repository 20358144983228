import { useEffect, useLayoutEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import useDataService from "../hooks/useDataService";

function Banner({ bannerRef, setSpacerHeight, ...props }) {

  const [user, setUser] = useState();

  const { apiGetClient } = useDataService();

  useEffect(() => {
    apiGetClient().then(setUser);
  }, [])

  useLayoutEffect(() => {
    setSpacerHeight(bannerRef.current.clientHeight - 25)
  }, [])

  return (
    <Container fluid ref={bannerRef} className="summary-banner">
      <Row id="spacer" style={{height: "50px"}}/>
      <Row className="mt-4 mb-3 justify-content-around text-white">
        <Col md="auto text-center">
          <h2>Hello, <b>{user?.firstName}</b>!</h2>
          <p>CryptoAdvisor API is still under development, but you can start exploring the features with your API keys.
          </p>
        </Col>
      </Row>
    </Container>
  )
}

export default Banner;