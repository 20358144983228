import { faArrowsRotate, faGroupArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import useDataService from "../hooks/useDataService";

function KeyList({ showNewKey, ...props }) {
  const [keys, setKeys] = useState([]);

  const { apiGetClientKeys, apiRotateClientKeys } = useDataService();

  const rotateKey = async (id) => {
    const response = await apiRotateClientKeys(id);
    showNewKey(response.key);
    apiGetClientKeys().then(setKeys);
  }

  useEffect(() => {
    apiGetClientKeys().then(setKeys);
  }, [])

  return (
    <Table>
      <thead>
        <tr>
          <th>Key ID</th>
          <th>Key</th>
          <th>Active</th>
          <th className="text-center">Rotate</th>
        </tr>
      </thead>
      <tbody>
        {keys.map(k => (
          <tr key={k.prefix}>
            <td>{k.prefix}</td>
            <td>{k.prefix}.***********</td>
            <td>true</td>
            <td className="text-center">
              <span className="rotate-button" onClick={() => rotateKey(k.prefix)}>
                <FontAwesomeIcon icon={faArrowsRotate}/>
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default KeyList;