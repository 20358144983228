import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { ROUTES } from "../routes";

function PrivateRoute({children, ...props}) {
    const { currentUser } = useAuth();

    // user context is NULL => user is not logged in => redirect to login page
    if (!currentUser) {
        return <Navigate to={ROUTES.Home}/>
    }

    // none of the scenarios above returned => user is allowed to see this resource
    return children;
}

export default PrivateRoute;