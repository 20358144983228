import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { ROUTES } from "../routes";

function PublicRoute({children, ...props}) {
    const { currentUser, isNewUser } = useAuth();

    // user context is set => user is logged in => redirect to dashboard
    if (currentUser && !isNewUser) {
        return <Navigate to={ROUTES.Dashboard}/>
    }

    // none of the scenarios above returned => user is allowed to see this resource
    return children;
}

export default PublicRoute;