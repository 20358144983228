import { Button, Container, Nav, Navbar, NavbarBrand } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { ROUTES } from "../routes";

function Navigation({...props}) {
    const { currentUser, logout } = useAuth();
    const navigate = useNavigate();

    return (
        <Navbar fixed="top" variant="dark" collapseOnSelect={true} expand="lg" style={{backgroundColor: "#0c1f4b"}}>
            <Container>
                <Navbar.Brand>
                    CryptoAdvisor
                    <span className="d-block border-top border-2 w-75"></span>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <Nav>
                        {/* TODO: Add links to other pages when pages are available */}
                        {/* <LinkContainer to="/about-us">
                            <Nav.Link>About Us</Nav.Link>
                        </LinkContainer> */}
                        {/* { currentUser && 
                            <LinkContainer to={ROUTES.AccountSettings}>
                                <Nav.Link>My account</Nav.Link> 
                            </LinkContainer>
                        } */}
                        { currentUser && 
                            <LinkContainer to={ROUTES.Dashboard}>
                                <Nav.Link>Dashboard</Nav.Link>
                            </LinkContainer>
                        }
                        { currentUser && <Nav.Link onClick={logout}>Log Out</Nav.Link> }
                        { ! currentUser && <Button className="me-2" onClick={() => navigate(ROUTES.SignUp)}>Sign Up</Button>}
                        { ! currentUser && <Button variant="light" onClick={() => navigate(ROUTES.LogIn)}>Log In</Button>}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Navigation;