import { useRef, useState } from "react";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import Banner from "./Banner";
import KeyList from "./KeyList";
import KeyModal from "./KeyModal";

function Dashboard({tempKey, setTempKey, clearKey, ...props}) {
  const ref = useRef();
  const [spacerHeight, setSpacerHeight] = useState();

  const [showKey, setShowKey] = useState(!!tempKey);

  const closeModal = () => {
    setShowKey(false);
    clearKey()
  }

  const showNewKey = (key) => {
    setTempKey(key);
    setShowKey(true);
  }

  return (
    <Container>
      <Banner bannerRef={ref} setSpacerHeight={setSpacerHeight} />
      <Row id="spacer" style={{ height: spacerHeight, minHeight: "15vh" }} />
      <h2 className="pb-2">Dashboard</h2>
      <Tab.Container id="left-tabs" defaultActiveKey="api-keys">
        <Row>
          <Col md={4}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="api-keys">
                  API Keys
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="usage">
                  Usage (TBD)
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col md={8}>
            <Tab.Content>
              <Tab.Pane eventKey="api-keys">
                <h4>My API Keys</h4>
                <KeyModal show={showKey} close={closeModal} apiKey={tempKey}/>
                <KeyList showNewKey={showNewKey}/>
              </Tab.Pane>
              <Tab.Pane eventKey="usage">
                <h4>Usage (TBD)</h4>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  )
}

export default Dashboard;