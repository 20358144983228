import { GoogleAuthProvider, signInWithPopup, getAdditionalUserInfo, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { useEffect } from "react";
import { createContext, useState, useContext } from "react";
import { auth } from "../services/firebase-service";

const AuthContext = createContext();

function useAuth() {
    return useContext(AuthContext);
}

function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState();
    const [idToken, setIdToken] = useState();
    const [loading, setLoading] = useState(true);
    const [isNewUser, setIsNewUser] = useState();
    
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                user.getIdToken().then(console.log);
                user.getIdToken().then(setIdToken);
            } else {
                setIdToken();
            }
            setCurrentUser(user);
            setLoading(false);
        });

        return () => {
            unsubscribe();
        };
    }, [])

    const createNewUser = async (email, password) => {
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            // userCredential.user.getIdToken().then(setIdToken);
            setIsNewUser(true);
            console.log(userCredential.user);
            return userCredential.user;
        } catch (error) {
            console.log(error);
            throw new Error("Signup error");
        }
    }

    const login = async (email, password) => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            setIsNewUser(false);
            return userCredential.user;
        } catch (error) {
            console.log(error);
            console.log("Login error");
        }
    }

    const refreshToken = async () => {
        const token = await currentUser.getIdToken(true)
        setIdToken(token);
    }
    
    const logout = () => {
        return auth.signOut();
    }

    const value = {
        currentUser,
        isNewUser,
        idToken,
        createNewUser,
        login,
        refreshToken,
        logout
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}

export {
    useAuth,
    AuthProvider
}