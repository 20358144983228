import { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { ROUTES } from "../routes";
import Dashboard from "./Dashboard";
import Home from "./Home";
import Login from "./Login";
import Navigation from "./Navigation";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Signup from "./Signup";

function Main(props) {
    const [tempKey, setTempKey] = useState();


    return (
        <Container>
            <Navigation />
            
            <Routes>
                <Route path={ROUTES.Home} element={<Home />}/>
                <Route path={ROUTES.SignUp} element={
                    <PublicRoute>
                        <Signup setTempKey={setTempKey} />
                    </PublicRoute>
                }/>
                <Route path={ROUTES.LogIn} element={
                    <PublicRoute>
                        <Login />
                    </PublicRoute>
                }/>
                {/* <Route path={ROUTES.Error} element={<AppError />}/> */}
                <Route path={ROUTES.Dashboard} element={
                    <PrivateRoute>
                        <Dashboard tempKey={tempKey} setTempKey={setTempKey} clearKey={() => setTempKey()}/>
                    </PrivateRoute>
                } />
                {/* <Route path={ROUTES.AccountSettings} element={
                    <PrivateRoute>
                        <MyAccount />
                    </PrivateRoute>
                } />
                <Route path={ROUTES.TokenRefresh} element={
                    <PrivateRoute>
                        <TokenRefresh />
                    </PrivateRoute>
                } />
                <Route path={ROUTES.Onboarding} element={
                    <PrivateRoute>
                        <Onboarding />
                    </PrivateRoute>
                } /> */}
                <Route path="*" element={<Navigate to={ROUTES.Dashboard} />} />
            </Routes>
        </Container>
    )
}

export default Main;