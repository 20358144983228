import { Button, Modal } from "react-bootstrap";

function KeyModal({show, close, apiKey, ...props}) {
  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>Your new API key</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>A new API key has been generated. We <b>will not be able to display this key again</b> as all API keys are stored encrypted.</p>
        
        <p><b>Copy the key and store it securely:</b></p>
        <pre className="bg-secondary bg-opacity-10 p-1">{apiKey}</pre>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => close()}>Done</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default KeyModal;