import { faAtom, faFileShield, faHandSparkles } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import useDataService from "../hooks/useDataService";
import { ROUTES } from "../routes";

function Home({ ...props }) {

    const { createUser } = useDataService();
    const { loginWithGoogle, currentUser } = useAuth();
    const [isNewUser, setIsNewUser] = useState(false);
    const navigate = useNavigate();

    const backgroundStyle = {
        backgroundImage: "url('/banner.webp')",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: -1,
        height: "120vh",
        width: "100vw",
        backgroundRepeat: "no-repeat"
    }

    useEffect(() => {
        if (currentUser && isNewUser) {
            createUser().then(() => setIsNewUser(false));
        }
    }, [currentUser, isNewUser])


    if (currentUser && !isNewUser) {
        return <Navigate to={ROUTES.Dashboard} />;
    }

    return (
        <Container>
            <div style={backgroundStyle}></div>
            <Row className="pt-5 mt-5 mb-5 flex-column align-items-center text-white">
                <Col md={2}></Col>
                <Col md={5} className="d-flex flex-column justify-content-center text-center mb-5">
                    <h2 className="pb-4">Welcome to CryptoAdvisor Dashboard</h2>
                    <p className="pb-2">Sign up for early access while CryptoAdvisor is in development!</p>
                    <Button onClick={() => navigate(ROUTES.SignUp)} className="mb-3">Get early access</Button>
                    <Button onClick={() => navigate(ROUTES.LogIn)} variant="outline-light">I have an account</Button>
                </Col>
                <Col md={5} className="text-center mt-3">
                    <Image height={550} src="/pic.png" />
                </Col>
            </Row>
        </Container>
    )
}

export default Home;